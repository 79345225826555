import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
  const SEO = ({ title, description, article }) => {
    const { pathname } = useLocation()
    const { site } = useStaticQuery(query)
    
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    author
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: defaultImage,
    url: `${siteUrl}${pathname}`,
  }
  
  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate} htmlAttributes={{ lang: "cs-cz" }}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} /> 
      <meta name="author" content={author}/>
      <meta name="robots" content="index, follow"/>
      <meta property="og:locale" content="cs_CZ"/>  
      {seo.title && <meta property="og:site_name" content={seo.title} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />
      
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  article: PropTypes.bool,
}