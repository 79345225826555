import React from "react"
import styles from "./Banner.module.scss"
import Button from "../Button"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Hero from "../../images/heroBase64"

const Banner = ({ type, heading, img, alt }) => {
  return (
    <div
      className={`${styles.banner} ${
        type === "main" ? styles.main : styles.page
      }`}
    >
      <div className={styles.overlay} />
      <div className={styles.textWrapper}>
        {type === "main" ? (
          <>
            <h1 className={styles.text}>
              Provádíme <span className={styles.bold}>instalatérské,</span>{" "}
              <span className={styles.bold}>topenářské, plynařské</span> <br />a{" "}
              <span className={styles.bold}>stavební</span> práce
            </h1>
            <Button link="/prodejna">Kamenná prodejna</Button>
          </>
        ) : (
          <>
            <h1 className={`${styles.text} ${styles.secondary}`}>{heading}</h1>
          </>
        )}
      </div>
      <div className={styles.overlay1}></div>
      {img && <Img className="heroimg" fluid={img} alt={alt}/>}
      {!img && <Hero/>}
    </div>
  )
}

export default Banner

Banner.propTypes = {
  type: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.object,
  alt: PropTypes.string,
}
